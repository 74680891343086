<template>
  <v-card @click="goToChampionshipPage()">
    <div class="d-flex flex-no-wrap justify-start">
      <v-avatar
        tile
        left
        size="100"
        color="#F7F7F7"
        class="ma-3 championship-image"
      >
        <v-img
          v-if="data.image"
          :src="data.image"
          contain
          max-height="70%"
          @error="imageErrorHandler()"
        />

        <span
          v-else
          class="display-2 white--text text-center"
        >
          <v-icon>
            mdi-trophy
          </v-icon>
        </span>
      </v-avatar>
      <div>
        <v-card-title v-text="data.name" />
        <v-card-text class="text-start">
          De {{formatDate(data.startsAt)}} à {{formatDate(data.endsAt)}}
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      selected: null
    }
  },
  methods: {
    remove () {
      this.$emit('deleteSelected', this.data._id)
    },
    update () {
      this.$emit('updateSelected', this.data._id)
    },
    imageErrorHandler () {
      this.data.image = imageOffIcon
    },
    goToChampionshipPage () {
      this.$store.commit('SET_SELECTED_CHAMPIONSHIP', this.data._id)
      this.$router.push(`/campeonatos/editar/${this.data._id}`)
    }
  }
}
</script>

<style scoped>
.championship-image {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}
</style>
